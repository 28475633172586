










import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class NumberText extends Vue {
  name = 'NumberText'
  @Prop() numberValue: number
  @Prop({ default: false }) isInteger: boolean

  $num (num: number, digits = 2): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: digits })
    }
    return null
  }
}
