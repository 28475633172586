import Vue from 'vue'
import Component from 'vue-class-component'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import validation from './common/element-val'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from './assets/element-ui-en'
import Axios from 'axios'
import VueI18n from 'vue-i18n'
import './assets/css/common.scss'
import { User } from '@/services/data'
import NumberText from '@/components/NumberText.vue'
import StockName from '@/components/StockName.vue'
import DateText from '@/components/DateText.vue'

if (window.location.protocol.startsWith('http:') && window.location.hostname !== 'localhost') {
  location.href = location.href.replace('http:', 'https:')
}

// 注册钩子函数
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

Vue.config.productionTip = false
Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Axios.interceptors.request.use(config => {
  // show loading
  const userData: User = store.state.user
  if (userData && userData.jwt) {
    config.headers.Authorization = 'bearer ' + userData.jwt
  }
  return config
})
// 响应拦截器
Axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response && error.response.status === 401) {
    const emptyUser: User = {
      email: null,
      jwt: null,
      accNo: null,
      name: null
    }
    store.commit('setUser', emptyUser)
  }
  return Promise.reject(error)
})
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: store.state.lang,
  messages: {
    en: require('./locales/en.json'),
    zh: require('./locales/zh.json')
  }
})

Vue.component('number-text', NumberText)
Vue.component('stock-name', StockName)
Vue.component('date-text', DateText)

validation.$t = function (key: string) {
  return i18n.t(key) as string
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
