






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class StockName extends Vue {
  name = 'StockName'
  @Prop() stkName: string
  @Prop() currency: string
}
