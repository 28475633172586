import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store/index'
import auth from '@/services/authService'
import { User } from '@/services/data'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/coin',
    name: 'coin',
    meta: 'anonymous',
    component: () => import('../views/Layouts/Empty.vue'),
    children: [
      {
        path: 'dual-investment',
        name: 'dual-investment',
        meta: 'anonymous',
        component: () => import('../views/Coin/DualInvestment.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'status',
        name: 'status',
        component: () => import('../views/Home/Status.vue')
      },
      {
        path: 'portfolio',
        name: 'portfolio',
        component: () => import('../views/Home/Portfolio.vue')
      },
      {
        path: 'watchlist',
        name: 'watchlist',
        component: () => import('../views/Home/Watchlist.vue')
      },
      {
        path: 'options',
        name: 'options',
        component: () => import('../views/Home/Options.vue')
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: () => import('../views/Home/Transactions.vue')
      },
      {
        path: 'alerts',
        name: 'alerts',
        component: () => import('../views/Home/Alerts.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('../views/Home/News.vue')
      },
      {
        path: 'chart',
        name: 'chart',
        component: () => import('../views/Home/Chart.vue')
      },
      {
        path: 'todo',
        name: 'todo',
        component: () => import('../views/Home/ToDo.vue')
      },
      {
        path: 'historical-data',
        name: 'historicalData',
        component: () => import('../views/Historical/List.vue')
      },
      {
        path: 'optCheck',
        name: 'optCheck',
        component: () => import('../views/Home/PutCallCheckList.vue')
      },
      {
        path: 'markets',
        name: 'markets',
        component: () => import('../views/Home/Markets.vue')
      },
      {
        path: 'Report',
        name: 'Report',
        component: () => import('../views/Report/Reports.vue'),
        children: [
          {
            path: 'stock-pl',
            name: 'stockPL',
            component: () => import('../views/Report/StockPL.vue')
          },
          {
            path: 'portfolio-debug',
            name: 'portfolioDebug',
            component: () => import('../views/Report/PortfolioReturn.vue')
          },
          {
            path: 'portfolio-return',
            name: 'portfolioReturn',
            component: () => import('../views/Report/PortfolioReturnChart.vue')
          },
          {
            path: 'stock-trades',
            name: 'stock-trades',
            component: () => import('../views/Report/StockTrades.vue')
          },
          {
            path: 'option-trades',
            name: 'option-trades',
            component: () => import('../views/Report/OptionTrades.vue')
          },
          {
            path: 'dividends',
            name: 'dividends',
            component: () => import('../views/Report/Dividends.vue')
          }
        ]
      },
      {
        path: 'analysis',
        name: 'analysis',
        component: () => import('../views/Analysis/Index.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/Settings/Index.vue'),
        children: [
          {
            path: 'mail',
            name: 'mail',
            component: () => import('../views/Settings/Mail.vue')
          },
          {
            path: 'user',
            name: 'user',
            component: () => import('../views/Settings/UserSettings.vue')
          },
          {
            path: 'trading',
            name: 'trading',
            component: () => import('../views/Settings/Trading.vue')
          },
          {
            path: 'app',
            name: 'app',
            component: () => import('../views/Settings/App.vue')
          },
          {
            path: 'account',
            name: 'account',
            component: () => import('../views/Settings/Account.vue')
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: () => import('../views/Settings/Notifications.vue')
          }
        ]
      },
      {
        path: 'freebieDetector',
        name: 'freebieDetector',
        component: () => import('../views/FreebieDetector/Index.vue'),
        children: [
          {
            path: 'list',
            name: 'list',
            component: () => import('../views/FreebieDetector/List.vue')
          },
          {
            path: 'log',
            name: 'log',
            component: () => import('../views/FreebieDetector/Log.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/notfound',
    name: 'notfound',
    meta: 'anonymous',
    component: () => import('../views/Page404.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: 'anonymous',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/forgotPwd',
    name: 'forgotPwd',
    meta: 'anonymous',
    component: () => import('../views/Login/ForgotPwd.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name == null) {
    next({ path: '/notfound' })
    return
  }
  const name = to.name.toLowerCase()
  if (name === 'notfound' || to.meta === 'anonymous') {
    next()
    return
  }
  if (name === 'login' && !localStorage.getItem('jwt')) {
    next()
    return
  }
  const checkLogin = (name === 'index' || name === 'login')
  if (!store.state.user || !store.state.user.jwt || checkLogin) {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      const res = await auth.checkLoginAsync(jwt)
      if (res.Result === true) {
        const userString = decodeURIComponent(escape(window.atob(jwt.split('.')[1])))
        const user = JSON.parse(userString)
        const userData: User = {
          email: user.LoginId,
          name: user.Name,
          uid: user.UId,
          accNo: user.AccNo,
          jwt: jwt
        }
        store.commit('setUser', userData)
        if (checkLogin) {
          next({ path: '/home/portfolio' })
          return
        }
        next()
      } else { // failure login
        localStorage.removeItem('jwt')
        next({ path: '/login' })
      }
    } else {
      localStorage.removeItem('jwt')
      next({ path: '/login' })
    }
  } else {
    next()
  }
})

export default router
