






import { Component, Vue, Prop } from 'vue-property-decorator'
import utility from '@/common/utility'

@Component
export default class DateText extends Vue {
  name = 'DateText'
  date: Date = null
  @Prop() dateStr: string

  mounted () {
    if (this.dateStr) {
      this.date = utility.parseTime(this.dateStr)
    }
  }

  getTime () {
    const h = this.date.getHours()
    const m = this.date.getMinutes().toString().padStart(2, '0')
    if (h === 0) return `$12:${m}AM`
    if (h < 12) return `${h}:${m}AM`
    return `${h - 12}:${m}PM`
  }
}
