export default {
  getBase64 (file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      let fileResult: string | ArrayBuffer = null
      reader.readAsDataURL(file)
      reader.onload = function () {
        fileResult = reader.result
      }
      reader.onerror = function (error) {
        reject(error)
      }
      reader.onloadend = function () {
        resolve(fileResult)
      }
    })
  },
  freezeArrayItems (arr: Array<any>) {
    for (let i = 0; i < arr.length; i++) {
      Object.freeze(arr[i])
    }
    return arr
  },
  freezeArrayAndItems (arr: Array<any>) {
    Object.freeze(arr)
    this.freezeArrayItems(arr)
    return arr
  },
  removeArrayItem (arr: Array<any>, item: any) {
    const index = arr.findIndex(p => p === item)
    if (index >= 0) {
      arr.splice(index, 1)
    }
  },
  getUUID (len: number, radix: number): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    const uuid = []
    radix = radix || chars.length
    if (len) {
      for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
    } else {
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      for (let i = 0; i < 36; i++) {
        if (!uuid[i]) {
          const r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join('')
  },
  getUUInt () {
    return parseInt(this.getUUID(6, 10))
  },
  getByKey (item: Record<string, any>, key: string) {
    const keys = key.split('.')
    if (keys.length === 1) {
      return item[key]
    }
    let first: string = keys.shift()
    let target: any = item[first]
    while (first != null) {
      first = keys.shift()
      if (first != null) {
        target = target[first]
      }
    }
    return target
  },
  copyArray<T> (keys: T[]) {
    const newArr: T[] = []
    for (let i = 0; i < keys.length; i++) {
      newArr.push(keys[i])
    }
    return newArr
  },
  parseDate (a: string): Date {
    if (a) {
      const index = a.indexOf(' ')
      if (index > 0) {
        a = a.substring(0, index)
      }
      const arr = a.split('/')
      return new Date(parseInt(arr[2]) + 2000, parseInt(arr[0]) - 1, parseInt(arr[1]))
    }
    return null
  },
  splitDate (a: string): string {
    if (a) {
      const index = a.indexOf(' ')
      if (index > 0) {
        a = a.substring(0, index)
      }
    }
    return a
  },
  parseTime (a: string): Date {
    if (a) {
      const index = a.indexOf(' ')
      let dateArr: string[] = null
      let timeArr: string[] = null
      if (index > 0) {
        dateArr = a.substring(0, index).split('/')
        timeArr = a.substr(index + 1).split(':')
        if (dateArr.length === 3 && timeArr.length === 3) {
          return new Date(parseInt(dateArr[2]) + 2000, parseInt(dateArr[0]) - 1, parseInt(dateArr[1]), parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]))
        }
      }
    }
    return null
  },
  download (name: string, data: BlobPart, type?: string) {
    if (!type) {
      type = 'text/csv;charset=utf-8;'
    }
    const blob = new Blob([data], { type: type })
    const fileName = name
    if ('download' in document.createElement('a')) { // 不是IE浏览器
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)// 下载完成移除元素
      window.URL.revokeObjectURL(url) // 释放掉blob对象
    } else { // IE 10+
      window.navigator.msSaveBlob(blob, fileName)
    }
  },
  sortArr (arr: any[]) {
    const sort = (a: any, b: any): number => {
      if (a === b) return 0
      return a > b ? 1 : -1
    }
    arr.sort(sort)
  },
  sortByFiled<T> (arr: T[], direction: 'Asc' | 'Desc', filedSelector: (item: T) => any) {
    const sort = (left: T, right: T): number => {
      const v = direction === 'Asc' ? 1 : -1
      if (filedSelector(left) === filedSelector(right)) return 0
      return filedSelector(left) > filedSelector(right) ? v : v * -1
    }
    arr.sort(sort)
  },
  getSymbolDisplay (symbolName: string, currency: string) {
    return currency === 'HKD' ? 'HKG:' + symbolName.padStart(4, '0') : symbolName
  },
  iSameSymbol (displayName: string, symbolName: string, currency: string) {
    return displayName === this.getSymbolDisplay(symbolName, currency)
  },
  parseSymbols (symbols: string[]) {
    return symbols.map(p => {
      const index = p.indexOf(':')
      return index > 0 ? p.substr(index + 1) : p
    })
  },
  getDistinct<T> (items: T[]) {
    const temp = new Set<T>()
    for (const item of items) {
      temp.add(item)
    }
    const arr: T[] = []
    for (const i of temp) {
      arr.push(i)
    }
    return arr
  }
}
