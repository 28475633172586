import Vue from 'vue'
import Vuex from 'vuex'
import { User } from '@/services/data'

Vue.use(Vuex)

let user: User = null
const userStr = sessionStorage.getItem('user')
const lang: string = sessionStorage.getItem('lang') || 'en'
if (userStr) {
  try {
    user = JSON.parse(userStr)
  } catch (e) {
    console.error(e)
  }
} else {
  user = {
    email: null,
    jwt: null,
    accNo: null,
    name: null
  }
}

export default new Vuex.Store({
  state: {
    user,
    lang: lang,
    notificationCount: null,
    agent: {}
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      if (!user) {
        sessionStorage.removeItem('user')
        return
      }
      sessionStorage.setItem('user', JSON.stringify(user))
    },
    setAgent (state, agent) {
      state.agent = agent
    },
    setNotificationCount (state, value) {
      state.notificationCount = value
    },
    setLang (state, value) {
      state.lang = value
    }
  },
  actions: {},
  modules: {}
})
