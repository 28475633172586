import config from './config'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiBooleanResult, ApiResult } from '@/services/data'

export default {
  async tryPostResult<T> (url: string, data?: any, reqConfig?: AxiosRequestConfig): Promise<ApiResult<T>> {
    try {
      return (await axios.post(config.host + url, data, reqConfig) as AxiosResponse<ApiResult<T>>).data
    } catch (e) {
      return Promise.resolve<ApiResult<T>>({ Error: e as string, Result: null })
    }
  },
  async tryPostBooleanResult (url: string, data?: any, reqConfig?: AxiosRequestConfig): Promise<ApiBooleanResult> {
    try {
      return (await axios.post(config.host + url, data, reqConfig) as AxiosResponse<ApiBooleanResult>).data
    } catch (e) {
      return Promise.resolve<ApiBooleanResult>({ Error: e as string, Result: false })
    }
  },
  async tryUploadResult<T> (url: string, data: any): Promise<ApiResult<T>> {
    const formData = new FormData()
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        formData.append(value, data[value])
      }
    }
    return this.tryPostResult<T>(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  async tryDownload (url: string, data?: any): Promise<Blob> {
    try {
      const response = await axios.post(config.host + url, data, { responseType: 'blob' })
      if (response.headers['content-type'].includes('json')) {
        return new Promise((resolve, reject) => {
          const { data } = response
          let error: string = null
          const reader = new FileReader()
          reader.onload = () => {
            const { result } = reader
            const errorJson = JSON.parse(result as string)
            error = errorJson.error
          }
          reader.onerror = function (error) {
            reject(error)
          }
          reader.onloadend = function () {
            reject(error)
          }
          reader.readAsText(data)
        })
      } else {
        return response.data
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
