import { ApiBooleanResult, ApiResult, JwtResult } from './data'
import serviceBase from '@/services/serviceBase'

export interface LogModel {
  Email: string;
  Password: string;
  Source?: string;
  Language: string;
  RememberMe: boolean;
}

export interface ChangePwdModel {
  OriPassword: string;
  NewPassword: string;
  Confirm?: string;
}

export interface IpRestrictionModel {
  IpRestrictionId: number;
  Ip: string;
  Mask: string;
  IsAllow: boolean;
}

export interface SecurityQuestionModel {
  Answer: string;
  Question: string;
  SecurityQuestionId: number;
}

export default {
  async checkLoginAsync (jwt: string): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Auth/checkLoginStatus', null, { headers: { Authorization: 'bearer ' + jwt } })
  },
  async loginAsync (data: LogModel): Promise<ApiResult<JwtResult>> {
    data.Source = 'Web'
    return await serviceBase.tryPostResult<JwtResult>('/Auth/Login', data)
  },
  async changePwdAsync (data: ChangePwdModel) {
    return await serviceBase.tryPostResult('/Auth/ChangePwd', data)
  },
  async getIpRestrictionsAsync (): Promise<ApiResult<IpRestrictionModel[]>> {
    return await serviceBase.tryPostResult<IpRestrictionModel[]>('/Auth/getIpRestrictions')
  },
  async setIpRestrictionAsync (data: IpRestrictionModel): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Auth/AddOrUpdateIpRestrictions', [data])
  },
  async getSecurityQuestionsAsync (acc?: string): Promise<ApiResult<SecurityQuestionModel[]>> {
    return await serviceBase.tryPostResult<SecurityQuestionModel[]>('/Auth/getSecurityQuestions', { UserAcc: acc })
  },
  async setSecurityQuestionsAsync (data: SecurityQuestionModel[]): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Auth/AddOrUpdateSecurityQuestions', data)
  }
}
