type ErrorCallback = (error?: Error) => void
type ValidationCallback = (rule: object, value: string, callback: ErrorCallback) => void
export type Ruler = Array<{ trigger: string; validator: ValidationCallback | Array<ValidationCallback> }>

export default {
  $t: function (key: string): string {
    return key
  },
  required (): ValidationCallback {
    const req = this.$t('val.required')
    return (rule, value: string, callback) => {
      if (value == null || value.toString().trim() === '') {
        callback(new Error(req))
      } else {
        callback()
      }
    }
  },
  intRequired (): ValidationCallback {
    const invalid = this.$t('val.intNumReq')
    const gt0 = this.$t('val.required')
    return (rule, value, callback) => {
      if (value == null || value.toString().trim() === '') {
        callback(new Error(invalid))
      } else if (parseInt(value) <= 0) {
        callback(new Error(gt0))
      } else if (!/^[1-9]\d*$/.test(value)) {
        callback(new Error(invalid))
      } else {
        callback()
      }
    }
  },
  intNumber (): ValidationCallback {
    const numReq = this.$t('val.numReq')
    return (rule, value, callback) => {
      if (isNaN(parseInt(value)) || !/^[-]?\d+$/.test(value)) {
        callback(new Error(numReq))
      } else {
        callback()
      }
    }
  },
  number (): ValidationCallback {
    const invalid = this.$t('val.numReq')
    const gt0 = this.$t('val.gt0')
    return (rule, value, callback) => {
      if (value && isNaN(parseFloat(value))) {
        callback(new Error(invalid))
      } else if (value && parseFloat(value) < 0) {
        callback(new Error(gt0))
      } else {
        callback()
      }
    }
  },
  numberRequired (): ValidationCallback {
    const req = this.$t('val.required')
    const invalid = this.$t('val.numReq')
    const gt0 = this.$t('val.gt0')
    return (rule, value, callback) => {
      if (value == null || value.toString().trim() === '') {
        callback(new Error(req))
      } else if (isNaN(parseFloat(value))) {
        callback(new Error(invalid))
      } else if (parseFloat(value) < 0) {
        callback(new Error(gt0))
      } else {
        callback()
      }
    }
  },
  requiredRule (trigger = 'blur'): Ruler {
    return [{
      validator: this.required(),
      trigger: trigger
    }]
  },
  requiredEmailRule (trigger = 'blur'): Ruler {
    return [{
      validator: this.required(),
      trigger: trigger
    }, {
      validator: this.email(),
      trigger: trigger
    }]
  },
  requiredIntRule (trigger = 'blur'): Ruler {
    return [{
      validator: this.intRequired(),
      trigger: trigger
    }]
  },
  expRule (req: boolean, exp: string, msg: string, trigger = 'blur'): Ruler {
    if (req) {
      return [{
        validator: this.required(),
        trigger: trigger
      }, {
        validator: this.regExp(exp, msg),
        trigger: trigger
      }]
    } else {
      return [{
        validator: this.regExp(exp, msg),
        trigger: trigger
      }]
    }
  },
  requiredNumberRule (trigger = 'blur'): Ruler {
    return [{
      validator: this.numberRequired(),
      trigger: trigger
    }]
  },
  email (): ValidationCallback {
    const req = this.$t('val.invalidEmail')
    return (rule, value, callback) => {
      if (!new RegExp('(\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*)').test(value)) {
        callback(new Error(req))
      } else {
        callback()
      }
    }
  },
  regExp (pattern: string, msg?: string): ValidationCallback {
    const req = msg || this.$t('val.invalidFormat')
    return (rule, value, callback) => {
      if (!new RegExp(pattern).test(value)) {
        callback(new Error(req))
      } else {
        callback()
      }
    }
  },
  compare (): ((p: () => string) => ValidationCallback) {
    const pwdNotMatch = this.$t('val.pwdNotMatch')
    return (targetFun: () => string): ValidationCallback => {
      return (rule, value, callback) => {
        if (!targetFun() && !value) {
          callback()
          return
        }
        if (value !== targetFun()) {
          callback(new Error(pwdNotMatch))
        } else {
          callback()
        }
      }
    }
  },
  fun (): ((p: () => boolean, msg: string) => ValidationCallback) {
    const req = this.$t('val.required')
    return (targetFun: () => boolean, msg: string): ValidationCallback => {
      msg = msg || req
      return (rule, value, callback) => {
        if (!targetFun()) {
          callback(new Error(msg))
        } else {
          callback()
        }
      }
    }
  },
  requireAll (...fields: Array<string>) {
    const obj = {} as any
    for (const index in fields) {
      obj[fields[index]] = this.requiredRule()
    }
    return obj
  }
}
